// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$chalet-zalona-primary: mat.define-palette(mat.$indigo-palette);
$chalet-zalona-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$chalet-zalona-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$chalet-zalona-theme: mat.define-light-theme(
  (
    color: (
      primary: $chalet-zalona-primary,
      accent: $chalet-zalona-accent,
      warn: $chalet-zalona-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($chalet-zalona-theme);

/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.css";
@import "~ngx-spinner/animations/ball-beat.css";
// @import "~@angular/material/prebuilt-themes/purple-green.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// *::-webkit-scrollbar,
// *::-webkit-scrollbar-thumb {
//   width: 26px;
//   border-radius: 13px;
//   background-clip: padding-box;
//   border: 10px solid transparent;
// }

// *::-webkit-scrollbar-thumb {
//   box-shadow: inset 0 0 0 10px;
// }

.zalona-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.cdk-overlay-container {
  z-index: 1003 !important;
}

.zalona-modalbox {
  font-family: "Nunito Sans", sans-serif;
  font: "Nunito Sans", sans-serif;
  color: #000;
  overflow: auto;
  .mat-dialog-title {
    font-family: "Nunito Sans", sans-serif;
    font: "Nunito Sans", sans-serif;
    color: #000;
  }
  .mat-dialog-container {
    background: white;
    color: #000;
    overflow: hidden;
    width: 600px;
  }
  @media (max-width: 600px) {
    .mat-dialog-container {
      padding: 1em;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}

h2 {
  font-family: "Nunito Sans", sans-serif !important;
}

table,
th,
tr,
td {
  border: 0px solid #000 !important;
}

.mat-calendar {
  font-family: "Nunito Sans", sans-serif;
  font: "Nunito Sans", sans-serif;

  .mat-calendar-table-header th {
    text-align: center;
    padding: 8px 0 8px 0;
  }

  .mat-icon-button {
    border-radius: 3px;
    &:focus {
      outline: none !important;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  .mat-calendar-period-button {
    background-color: #000;
    color: white;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 2px;
    border: none !important;
    &:hover {
      background-color: #a2783a;
    }
    &:focus {
      outline: none !important;
      border: none;
    }
  }
  .mat-calendar-arrow {
    fill: white;
  }
}
.mat-calendar-body-cell-content {
  border-radius: 3px !important;
}
.mat-calendar-body-selected {
  background-color: black !important;
  color: #fff;
  border-radius: 3px;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled)
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  transition: all 0.3s;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #a2783a;
  color: white;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #a2783a !important;
  }
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}

.inline-calendar-card {
  button:focus {
    outline: none !important;
  }
  .mat-calendar-body-label {
    opacity: 0 !important;
  }
}

.chipClass {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #a2783a;
  border-radius: 50%;
  z-index: 9999;
  opacity: 0.7;
}

.ngx-spinner-overlay {
  backdrop-filter: blur(4px);
}

// .ngx-spinner-overlay::before {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   height: 100%;
//   width: 100%;
//   background: url("./assets//images/floralBg3.jpg");
//   background-size: cover;
//   // background-blend-mode: color;
//   opacity: 0.4;
//   filter: blur(1px);
// }

.bigText {
  font-size: 24px;
  color: #a2783a;
}
